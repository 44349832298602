<template>
  <base-info-card title="Instagram">
    <a
      v-for="feature in features"
      :key="feature"
      :text="feature"
      class="mb-8 grey--text body-1 d-block text-none"
      href="https://www.instagram.com/elmagomerlincerrajeria"
      v-html="`&rsaquo; ${feature}`"
    />
  </base-info-card>
</template>

<script>
  export default {
    name: 'SectionFeaturesAlt',

    data: () => ({
      features: ['@elmagomerlincerrajeria',
        // 'Zero Installation',
        // 'Easy Customization',
        // 'User Friendly Design',
        // '24/7 Support Desk',
        // 'SEO Optimized',
      ],
    }),
  }
</script>
